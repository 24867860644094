import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import Select from "react-select";
import { Provinces } from "./provinces";
export type Inputs = {
  tipo_persona: { value: string; label: string };
  provincia: (typeof Provinces)[0];
  kmAnno: string;
  budget: { value: string; label: string };
  piva: string;
  cf: string;
  maxtime: string;
  whatYouNeed: string;
  nome: string;
  cognome: string;
  nomecliente: string;
  email: string;
  cellulare: string;
  whereYouKnowUs: string;
  note: string;
  pot: string;
};

const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 0.5em;
`;
const Label = styled.div`
  font-weight: bold;
`;
export function RadioGroup(props: {
  label: string;
  name: keyof Inputs;
  type?: "radio" | "checkbox";
  options: string[];
}) {
  const { register } = useFormContext(); // retrieve all hook methods

  return (
    <div>
      <Label>{props.label + "*:"}</Label>
      <RadioGroupContainer>
        {props.options.map((option) => (
          <div key={option + props.name}>
            <input
              required={props.type !== "checkbox"}
              type={props.type || "radio"}
              id={option}
              value={option}
              {...register(props.name)}
            />
            <label htmlFor={option}>{option}</label>
          </div>
        ))}
      </RadioGroupContainer>
    </div>
  );
}

const StyledSelect = styled(Select)`
  .react-select__control {
    max-width: 300px;
    margin-top: 0.5em;
  }
  .react-select__menu {
    max-width: 300px;
  }
`;
export function SelectGroup(props: {
  label: string;
  name: keyof Inputs;
  options: { value: any; label: string }[];
}) {
  const { control } = useFormContext(); // retrieve all hook methods

  return (
    <div>
      <Label>{props.label + "*:"}</Label>
      <Controller
        name={props.name}
        control={control}
        render={({ field }) => (
          <StyledSelect
            className="react-select-container"
            classNamePrefix="react-select"
            {...field}
            options={props.options}
          />
        )}
      />
    </div>
  );
}
const InputGroupContainer = styled.div`
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="number"] {
    height: 38px;
    width: 100%;
    max-width: 300px;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
    border-style: solid;
    border-width: 1px;
    padding-left: 0.5em;
    border-color: hsl(0, 0%, 80%);
    margin-top: 0.5em;
  }
  input[type="number"] {
    max-width: 100px;
  }
`;
export function InputGroup(props: {
  label: string;
  name: keyof Inputs;
  type?: "email" | "text" | "tel" | "number";
}) {
  const { register } = useFormContext(); // retrieve all hook methods

  return (
    <InputGroupContainer>
      <Label>{props.label + "*:"}</Label>
      <input required type={props.type || "text"} {...register(props.name)} />
    </InputGroupContainer>
  );
}

import styled from "styled-components";
import { WrapContainer } from "../../components/wrapContainer";
import { useDescriptiveBlockQuery } from "../../generated/graphql";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { RoundedButton } from "../../components/roundedButton";
import { InputGroup, Inputs, RadioGroup, SelectGroup } from "./inputs";
import { useAddLeadMutation, LeadInput } from "../../generated/graphql";
import { Provinces } from "./provinces";
import { useEffect, useState } from "react";
import { Spinner } from "@fluentui/react-components";

const formatter = new Intl.DateTimeFormat("fr-CA", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

const Container = styled.form`
  max-width: var(--page-max-width);
  width: 100%;
  padding-bottom: 2em;
  p {
    max-width: 70ch;
  }
  h1 {
    line-height: 1;
  }

  textArea {
    width: 100%;
    max-width: 70ch;
    height: 200px;
    font-family: inherit;
    padding: 0.5em;
    box-sizing: border-box;
  }
`;

const Label = styled.div`
  font-weight: bold;
`;
const InputsGrid = styled.div`
  display: grid;
  grid-row-gap: 2em;
  grid-column-gap: 2em;
  padding-bottom: 2em;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
`;

export function PreventivoForm() {
  const methods = useForm<Inputs>({
    defaultValues: { tipo_persona: { label: "Azienda", value: "azienda" } },
  });
  const { register, handleSubmit, watch, reset } = methods;
  const [status, setStatus] = useState<"waiting" | "loading" | "finished">(
    "waiting"
  );
  const [{ data }] = useDescriptiveBlockQuery({
    variables: { id: "MUFYsedXzs5ahsE2Ucy4T" },
  });
  const [_mutResponse, addLead] = useAddLeadMutation();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setStatus("loading");
    const formattedDate = formatter.format(new Date());
    const formattedNotes = `Quanti chilometri percorri all'anno: ${data.kmAnno}
Per quando ne hai bisogno?: ${data.maxtime}
Cosa cerchi dalla tua auto?: ${data.whatYouNeed}
Dove hai sentito parlare di noi?: ${data.whereYouKnowUs}
Note: ${data.note}`;
    const lead: LeadInput = {
      tipo_persona: data.tipo_persona.value,
      nome: data.nome,
      cognome: data.cognome,
      email: data.email,
      cellulare: data.cellulare,
      provincia: data.provincia.value,
      regione: data.provincia.regione,
      cf: data.cf,
      piva: data.piva,
      nomecliente: data.nomecliente,
      budget: data.budget.value,
      pot: data.pot,
      datacontatto: formattedDate,
      note: formattedNotes,
    };

    const response = await addLead({ arg1: lead });
    console.log(response);
    if (!response.error) {
      setStatus("finished");
      reset();
    }
  };

  const tipo_persona = watch("tipo_persona");
  useEffect(() => {
    if (tipo_persona.label === "Azienda") {
      methods.setValue("nome", "");
      methods.setValue("cognome", "");
      methods.setValue("cf", "");
    } else {
      methods.setValue("nomecliente", "");
      methods.setValue("piva", "");
    }
  }, [methods, tipo_persona]);
  return (
    <WrapContainer grey>
      <FormProvider {...methods}>
        <Container onSubmit={handleSubmit(onSubmit)}>
          <h1>{data?.descriptiveBlock?.title}</h1>

          {documentToReactComponents(data?.descriptiveBlock?.description?.json)}
          <InputsGrid>
            <SelectGroup
              label="Che tipo di cliente sei?"
              name="tipo_persona"
              options={[
                { label: "Azienda", value: "azienda" },
                {
                  label: "Libero Professionista",
                  value: "libero professionista",
                },
                { label: "Privato", value: "privato" },
              ]}
            />
          </InputsGrid>

          <InputsGrid>
            <SelectGroup
              label="Provincia in cui ti trovi"
              name="provincia"
              options={Provinces}
            />

            {(watch("tipo_persona").label === "Azienda" ||
              watch("tipo_persona").label === "Libero Professionista") && (
              <>
                <InputGroup name="nomecliente" label="Nome Azienda" />
                <InputGroup name="piva" label="Partita Iva" />
              </>
            )}

            {watch("tipo_persona").label === "Privato" && (
              <>
                <InputGroup name="nome" label="Nome" />
                <InputGroup name="cognome" label="Cognome" />
                <InputGroup name="cf" label="Codice Fiscale" />
              </>
            )}

            <InputGroup name="email" label="Email" type="email"></InputGroup>
            <InputGroup
              name="cellulare"
              label="Numero di telefono"
              type="tel"
            />
          </InputsGrid>
          <InputsGrid>
            <RadioGroup
              label="Quanti chilometri percorri all'anno?"
              name={"kmAnno"}
              options={[
                "10 mila o meno",
                "Tra i 10 e i 20 mila",
                "Tra i 20 e i 30 mila",
                "Oltre i 30 mila",
              ]}
            />

            <RadioGroup
              label="Per quando ne hai bisogno?"
              name={"maxtime"}
              options={["Appena possibile", "Entro 3 mesi", "Non ho fretta"]}
            />
            <RadioGroup
              label="Cosa cerchi dalla tua auto?"
              name={"whatYouNeed"}
              type="checkbox"
              options={[
                "Spazio",
                "Prestazioni",
                "Brand di lusso",
                "Bassi consumi",
                "Basse emissioni",
                "Aspetto sportivo",
              ]}
            />
            <SelectGroup
              label="Qual è il tuo budget mensile senza anticipo?"
              name="budget"
              options={[
                { value: "300-400", label: "Tra i 300€ e i 400€" },
                { value: "400-650", label: "Tra i 400€ e i 650€" },
                { value: "650-900", label: "Tra i 650€ e i 900€" },
                { value: "900+", label: "Oltre i 900€" },
              ]}
            />
            <InputGroup
              name="pot"
              label="Di quante automobili hai bisogno?"
              type="number"
            />
          </InputsGrid>

          <InputsGrid>
            <RadioGroup
              label="Dove hai sentito parlare di noi?"
              name={"whereYouKnowUs"}
              options={["Instagram", "TikTok", "YouTube", "Altro"]}
            />
          </InputsGrid>

          <InputsGrid>
            <div>
              <Label>Note:</Label>
              <textarea {...register("note")} />
            </div>
          </InputsGrid>

          {status === "finished" && (
            <>Grazie! Ti ricontatteremo al più presto</>
          )}
          {status === "waiting" && (
            <RoundedButton className="solid" type="submit">
              Invia
            </RoundedButton>
          )}
          {status === "loading" && <Spinner style={{ width: 50 }} />}
        </Container>
      </FormProvider>
    </WrapContainer>
  );
}
